import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AppState {
  header: string;
  // eslint-disable-next-line no-unused-vars
  setHeader: (newHeader: string) => void;
}

export const useAppStore = create<AppState>()(
  persist(
    (set) => ({
      header: 'Parranda Boricua',
      setHeader: (newHeader) => set(() => ({ header: newHeader })),
    }),
    { name: 'app-state' },
  ),
);
