import './index.css';

import { IonHeader, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import React from 'react';

const Fallback: React.FC = () => {
  return (
    <>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle className="toolbar-title">LOADING</IonTitle>
        </IonToolbar>
      </IonHeader>
      <div className="about-container">
        <div className="bg-about" />
        <div className="bg-about bg2-about" />
        <div className="bg-about bg3-about" />
        <IonRow>
          <h1 className="about-title">LOADING</h1>
        </IonRow>
      </div>
    </>
  );
};

export default Fallback;
