import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getPerformance } from 'firebase/performance';

const firebaseConfig = {
  apiKey: 'AIzaSyAfoS97Tt_zImTGjI9xYtSPwTJdJHEaKKE',
  authDomain: 'parranda-boricua-25b8f.firebaseapp.com',
  projectId: 'parranda-boricua-25b8f',
  storageBucket: 'parranda-boricua-25b8f.appspot.com',
  messagingSenderId: '98836019523',
  appId: '1:98836019523:web:ae82f3e6fa560daca357f6',
  measurementId: 'G-V5CD27YNXV',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const perf = getPerformance(app);

export const logScreenView = (screenName: string) => {
  // @ts-ignore
  logEvent(analytics, 'screen_view', {
    firebase_screen: screenName,
  });
};
