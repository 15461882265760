import './Main.css';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';

import {
  IonApp,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonMenuButton,
  IonModal,
  IonRouterOutlet,
  IonRow,
  IonTitle,
  IonToolbar,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { closeOutline, contrastOutline, settingsOutline } from 'ionicons/icons';
import React, { lazy, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route } from 'react-router-dom';

import pr from './assets/images/pr-icon-min.png';
import usa from './assets/images/usa-icon-min.png';

const Menu = lazy(() => import('./components/Menu'));
const About = lazy(() => import('./pages/about'));
const Home = lazy(() => import('./pages/home'));
const Instruments = lazy(() => import('./pages/instruments'));
const Cuatro = lazy(() => import('./pages/instruments/cuatro'));
const Guitar = lazy(() => import('./pages/instruments/guitar'));
const Cagueno = lazy(() => import('./pages/instruments/guitar/cagueno'));
const Chorreado = lazy(() => import('./pages/instruments/guitar/chorreado'));
const Fajardeno = lazy(() => import('./pages/instruments/guitar/fajardeno'));
const Jibaro = lazy(() => import('./pages/instruments/guitar/jibaro'));
const Parranda = lazy(() => import('./pages/parranda'));
const Song = lazy(() => import('./pages/songbook/Song'));
const SongList = lazy(() => import('./pages/songbook/SongList'));
import { useAppStore } from './store/appStore';
import { LANGUAGE_PR, LANGUAGE_USA } from './util/constants';

setupIonicReact({
  swipeBackEnabled: false,
});

// Use matchMedia to check the user preference
const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
const toggleDarkTheme = (shouldAdd: boolean) =>
  document.body.classList.toggle('dark', shouldAdd);

const toggleDarkModeHandler = () => document.body.classList.toggle('dark');

toggleDarkTheme(prefersDark.matches);

const App: React.FC = () => {
  const [header] = useAppStore((state) => [state.header]);
  const { i18n, t } = useTranslation();
  // eslint-disable-next-line no-undef
  const modal = useRef<HTMLIonModalElement>(null);

  const changeLanguage = (newLang: string) => {
    i18n.changeLanguage(newLang);
  };
  return (
    <IonApp>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButton id="open-options" slot="end">
            <IonIcon slot="icon-only" icon={settingsOutline} />
          </IonButton>
          <IonModal ref={modal} trigger="open-options">
            <IonHeader>
              <IonToolbar color="primary">
                <IonTitle className="toolbar-title">{t('settings')}</IonTitle>
                <IonButton
                  onClick={() => modal.current?.dismiss()}
                  slot="end"
                  color="light"
                  fill="clear">
                  <IonIcon
                    slot="icon-only"
                    icon={closeOutline}
                    className=".color-white"
                  />
                </IonButton>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <IonGrid>
                <IonRow>
                  <IonCol>{t('theme')}</IonCol>
                  <IonCol>
                    <IonButton onClick={toggleDarkModeHandler}>
                      <IonIcon slot="icon-only" icon={contrastOutline} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonGrid>
                <IonRow>
                  <IonCol>{t('language')}</IonCol>
                  <IonCol>
                    {i18n.language === LANGUAGE_PR && (
                      <IonButton onClick={() => changeLanguage(LANGUAGE_USA)}>
                        <IonImg src={pr} className="language" />
                      </IonButton>
                    )}
                    {i18n.language === LANGUAGE_USA && (
                      <IonButton onClick={() => changeLanguage(LANGUAGE_PR)}>
                        <IonImg src={usa} className="language" />
                      </IonButton>
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
          </IonModal>
          <IonTitle className="toolbar-title">{header}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonReactRouter>
        <Menu />
        <IonRouterOutlet id="main">
          <Route path="/" exact={true}>
            <Redirect to="/home/" />
          </Route>
          <Route path="/home/" exact={true} component={Home} />
          <Route path="/songbook/" exact={true} component={SongList} />
          <Route path="/song/:name" exact={true} component={Song} />
          <Route path="/parranda/" exact={true} component={Parranda} />
          <Route path="/about/" exact={true} component={About} />
          <Route path="/instruments/" exact={true} component={Instruments} />
          <Route path="/instruments/guitar" exact={true} component={Guitar} />
          <Route path="/instruments/guitar/cagueno" exact={true} component={Cagueno} />
          <Route
            path="/instruments/guitar/fajardeno"
            exact={true}
            component={Fajardeno}
          />
          <Route path="/instruments/guitar/jibaro" exact={true} component={Jibaro} />
          <Route
            path="/instruments/guitar/chorreado"
            exact={true}
            component={Chorreado}
          />
          <Route path="/instruments/cuatro" exact={true} component={Cuatro} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
