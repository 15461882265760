import './i18n';
import './util/analytics';

import i18next from 'i18next';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
// @ts-ignore
import { registerSW } from 'virtual:pwa-register';

import App from './App';
import Fallback from './pages/fallback';

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={<Fallback />}>
        <App />
      </Suspense>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};
const registerServiceWorker = () => {
  registerSW({
    onNeedRefresh() {},
    onOfflineReady() {},
    onRegisterError(error: any) {
      console.log(error);
    },
  });
};

i18next.on('initialized', renderApp);
registerServiceWorker();
